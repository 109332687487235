import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailReply } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';

export class PlatformLogo extends Component {
    state = {
        platform: this.props.platform
    };

    style = {
        listStyleType: "none"
    }
    render() {
        let icon = null;

        switch (this.state.platform) {
            case "Github":
                icon = <FontAwesomeIcon icon={faGithub} />;
                break;
            case "LinkedIn":
                icon = <FontAwesomeIcon icon={faLinkedin} />;
                break;
            case "Twitter":
                icon = <FontAwesomeIcon icon={faTwitter} />;
                break;
            case "Email":
                icon = <FontAwesomeIcon icon={faMailReply} />;
                break;
            case "Instagram":
                icon = <FontAwesomeIcon icon={faInstagram} />;
        }

        return (
            <React.Fragment>
                {icon}
            </React.Fragment>
        )
    }
}