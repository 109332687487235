import React, { Component } from "react";
import githubLogo from "../assets/GitHub-Mark-Light-32px.png";
import { PlatformLogo } from "./platformLogo";
// import gif from "./eye-icon-animate.gif";

export class Cat extends Component {
	state = {
		type: this.props.line.type,
		value: this.props.line.value
	};

	style = {
		align: "middle"
	};

	years = new Date().getFullYear() - 2009;

	information = {
		about:
			`My name is Srdjan and I've been a software developer and architect for past ${this.years} years. I've worked on a variety of projects, from small web applications to large enterprise systems. Main focus of my expertise is .Net and Microsoft stack, as well as NodeJS, but I'm also very proficient with JavaScript as well as front-end framworks such as React and Angular. Additionally, I'm a AWS cloud architect in training, with some experience with Google Cloud Platform and especially Firebase.`,
		experience: [
			{
				company: "symphony.is",
				position: "Solutions Architect",
				startDate: "Feb 2022",
				endDate: "Present",
				description: ""
			},
			{
				company: "TATATU",
				position: "Senior Software Engineer",
				startDate: "Jun 2021",
				endDate: "Feb 2022",
				description: `Designed an event driven architecture for the company solution, and assisted in planning schedule for moving from a legacy system to a new one. This resulted in getting an almost 10x increase in performance and system reliability. Designed and created service templates that helped streamline development process and standardize new service creation and setup.`
			},
			{
				company: "Svea Ekonomi",
				position: "Senior Software Engineer",
				startDate: "Sep 2017",
				endDate: "Jun 2021",
				description: `Tech lead and lead developer on OAUTH/OIDC authorization service project used by the company. Working with and mentoring developers on their onboarding process to the company and projects. Developing new tools with operations and quality assurance departments to improve and polish release processes. Directed both remote and on-premise teams dedicated to application design and quality assurance testing.`
			},
			{
				company: "Nucleus Group",
				position: "Software Development Team Lead",
				startDate: "Feb 2016",
				endDate: "Sep 2017",
				description: `Responsible to provide consulting to team members, project managers and product owners to ensure better flow of knowledge and information required to start and maintain a project. Responsible for ensuring the team is consistently delivering working software to the standards the company expects. Ensure the team is self-organizing so that we take collective responsibility for the work we do, not to tell people what to do but to enable them to do it themselves.`
			},
			{
				company: "Visit.com Adriatic",
				position: "Senior Software Engineer",
				startDate: "Sep 2014",
				endDate: "May 2017",
				description: `Develop and direct software system testing and validation procedures, programming, and documentation. Modify existing software to correct errors, to adapt it to new hardware, or to upgrade interfaces and improve performance. Confer with systems analysts, engineers, programmers and others to obtain information on project limitations and capabilities, performance requirements and interfaces.`
			},
			{
				company: "AS Soft Team",
				position: "Senior Software Engineer",
				startDate: "Oct 2014",
				endDate: "May 2015",
				description: `Worked as a part time developer for a striving company, using the latest web technologies to improve existing and develop new applications and services. Technologies mainly used: ASP.Net MVC5, jQuery, native Javascript, nUnit testing tools, etc.`
			},
			{
				company: "PurchaseControl",
				position: "Senior Software Engineer",
				startDate: "Jan 2014",
				endDate: "Aug 2014",
				description: ``,
			},
			{
				company: "Intersolia",
				position: "Software Engineer",
				startDate: "Nov 2012",
				endDate: "Jan 2014",
				description: ` Development and maintenance of an existing complex web application using ASP.NET MVC 2 framework, NHibernate, MS SQL Server 2008, StructureMap, NUnit, jQuery, SVN source control. Writing Unit and Integration tests. Designing and implementing SQL Server tables, functions, stored procedures, views and triggers. Working in an agile team of 8 members`
			},
			{
				company: "Cyrillic Software",
				position: "Software Engineer",
				startDate: "Apr 2012",
				endDate: "Nov 2012",
				description: `Development and maintainance of new web applications using ASP.NET MVC 3 framework, Entity Framework, MS SQL Server 2008, SVN source control, VB.NET. Implementing HTML5/CSS3 design solutions in developed web applications. Working with project manager on designing and documenting application development process and planning`
			},
			{
				company: "Screen DOO",
				position: "Web Developer",
				startDate: "Mar 2009",
				endDate: "Mar 2011",
				description: `Development and maintainance of an e-commerce web application using ASP.NET MVC 3 framework, MS SQL Server 2008, SVN source control, C#.NET. Implementing HTML/CSS design solutions in developed web application.`
			}
		],
		projects: [
			{
				projectName: "Arch Wizard",
				liveDemo: "https://www.arch-wizard.app/",
				linkToGithub: "",
				description: `Arch Wizard 🧙‍♂️ is a platform built for everyone involved in lifetime of a software. Provides a simple recommendation system for architecture patterns based on system requirements and allows for markdown documentation so you can either showcase your idea by publishing it or use it as an architecture design prototype or even just a blog post.`
			},
			{
				projectName: "Odds Roll Call",
				liveDemo: "https://rollcall-1565034941632.web.app/",
				linkToGithub: "",
				description: `Odds Roll Call (ORC) is a tool for managing combat encounters for Dungeons & Dragons. It features character creation, importing owned character from D&D Beyond, initiative tracker and calculator, interactive customizable map. Initiative tracker and interactive map are seemlessly integrated and work in realtime. ORC is a serverless Angular app, powered by Firebase features.`
			}
		],
		social: [
			{
				platform: "Github",
				link: "https://github.com/rexdefuror"
			},

			{
				platform: "LinkedIn",
				link: "https://www.linkedin.com/in/rexdefuror/"
			},
			{
				platform: "Twitter",
				link: "https://twitter.com/rexdefuror"
			}
		],
		contacts: [
			{
				platform: "Email",
				link: "mailto:srdjan.debic@gmail.com",
			},
			{
				platform: "Instagram",
				link: "https://www.instagram.com/rexdefuror/",
			},
			{
				platform: "Twitter",
				link: "https://twitter.com/rexdefuror",
			}
		]
	};
	render() {
		return (
			<React.Fragment>
				<p className="prompt"> {this.props.line.value} </p>
				{this.handelCd()}
			</React.Fragment>
		);
	}

	handelCd = () => {
		const navigation = this.state.value.split(" ")[1];
		if (navigation) {
			const lower = navigation.toLowerCase();
			if (lower === "about") {
				return <p className="result">{this.information.about}</p>;
			} else if (lower === "experience" || lower === "exp") {
				return (
					<React.Fragment>
						{this.information.experience.map(everyExperience => {
							return (
								<div className="result">
									<span class="white"><strong>{everyExperience.position}</strong></span> - {everyExperience.company}  <br />
									{everyExperience.startDate} - {everyExperience.endDate} <br />
									{everyExperience.description}
									<hr />
								</div>

							);
						})}
					</React.Fragment>
				)
			} else if (lower === "projects" || lower === "project") {
				return (
					<React.Fragment>
						{this.information.projects.map(everyProject => {
							return (
								<div className="result">
									{everyProject.projectName}
									<a href={everyProject.liveDemo} target="_blank">
										Live Link
									</a>
									<p>
										{everyProject.description}
									</p>
								</div>
							);
						})}
					</React.Fragment>
				);
			} else if (lower == "contactme" || lower == "contact me" || lower == "contact" || lower == "contacts") {
				return (
					<React.Fragment>
						{this.information.contacts.map(everyContact => {
							return (
								<p className="result">
									<a href={everyContact.link} target="_blank">
										<PlatformLogo platform={everyContact.platform} />
									</a>
									<a href={everyContact.link} target="_blank">
										{everyContact.platform}
									</a>
								</p>
							);
						})}
					</React.Fragment>
				)
			} else if (
				lower === "socials" ||
				lower === "social"
			) {
				return (
					<React.Fragment>
						{this.information.social.map(everySocial => {
							return (
								<p className="result">
									<a href={everySocial.link} target="_blank">
										<PlatformLogo platform={everySocial.platform} />
									</a>
									<a href={everySocial.link} target="_blank">
										{everySocial.platform}
									</a>
								</p>
							);
						})}
					</React.Fragment>
				);
			} else {
				return <p className="result">Opps wrong input</p>;
			}
		} else {
			return <p className="result">Opps wrong input</p>;
		}
	};
}

export default Cat;
